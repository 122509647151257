<template>
  <div class="popular_up_master">
    <div class="header">
      <div class="back" @click="$router.go(-1)">
        <svg-icon icon-class="back"></svg-icon>
      </div>
      <h2>热门UP主</h2>
    </div>
    <div class="main">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isNoData="isNoData"
      >
        <div class="info_box" v-for="item in list" :key="item.uid">
          <div class="avatar">
            <ImgDecypt :src="item.portrait" />
          </div>
          <div class="info">
            <div class="info_detail">
              <div class="name">{{ item.name }}</div>
              <div class="focus" @click="focus(item)" v-if="!item.hasFollow">
                <span>+</span>关注
              </div>
            </div>
            <div class="info_detail summary">
              {{ item.summary || "这个人很懒，什么都没有留下" }}
            </div>
            <div class="info_detail">
              <div class="work_number">作品数：{{ item.totalWorks }}</div>
              <div class="watch_more" @click="jumpHomePage(item)">
                查看全部作品<svg-icon
                  class="right_orange_arrow"
                  iconClass="right_orange_arrow"
                ></svg-icon>
              </div>
            </div>
          </div>
        </div>
      </PullRefresh>
    </div>
  </div>
</template>
<script>
import { queryUpRecom } from "@/api/community";
import PullRefresh from "@/components/PullRefresh";
import { careOrcancle } from "@/api/user";
import { Toast } from "vant";
import ImgDecypt from "@/components/ImgDecypt";
export default {
  name: "popularUpMaster",
  components: {
    PullRefresh,
    ImgDecypt,
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 20,
      list: [],
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
    };
  },
  created() {
    this.getPopularList();
  },
  methods: {
    // 获取热门up主
    async getPopularList(type) {
      let req = {
        pageSize: String(this.pageSize),
        pageNumber: String(this.pageNumber),
      };
      let res = await this.$Api(queryUpRecom, req);
      this.loading = false;
      this.refreshing = false;
      if (res.code === 200) {
        let list = res.data || [];
        if (type === "refresh") {
          this.list = list;
        } else {
          this.list = this.list.concat(list);
        }
        if (this.pageNumber == 1 && this.list.length == 0) {
          this.isNoData = true;
        }
        this.finished = true;
        // if (!res.data.hasNext) {
        //   this.finished = true;
        //   return;
        // }
      }
    },
    onLoad() {
      this.loading = true;
      this.pageNumber++;
      this.getPopularList();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.getPopularList("refresh");
    },
    // 跳转用户主页
    jumpHomePage(item) {
      this.$router.push({ path: "/userHomePage", query: { uid: item.uid } });
    },
    //关注
    async focus(upMaster) {
      let req = {
        followUID: parseInt(upMaster.uid),
        isFollow: true,
      };
      try {
        let res = await this.$Api(careOrcancle, req);
        if (res.code === 200) {
          Toast("关注成功");
          this.list = this.list.map((item) => {
            if (item.uid == upMaster.uid) {
              item.hasFollow = true;
            }
            return item;
          });
          return;
        }
        Toast(res.tip || "关注失败");
      } catch (e) {
        Toast("关注失败");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.popular_up_master {
  height: 100%;
  .header {
    display: flex;
    height: 44px;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #ffffff;

    .back {
      position: absolute;
      left: 20px;
      font-size: 24px;
    }
    svg {
      font-size: 26px;
    }
    h2 {
      font-size: 16px;
      color: #ff678f;
    }
  }
  .main {
    padding: 0 12px 0px;
    overflow-y: auto;
    height: calc(100% - 64px);
    -webkit-overflow-scrolling: touch;
    .info_box {
      margin-top: 10px;
      background-color: #ffffff;
      border-radius: 4px;
      padding: 10px 0px 10px 12px;
      display: flex;
      .avatar {
        height: 100px;
        width: 100px;
        border-radius: 4px;
        overflow: hidden;
      }
      .info {
        flex: 1;
        padding: 0 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        color: rgb(194, 194, 194);
        .info_detail {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          color: #444444;
          .work_number {
            font-size: 12px;
          }
          .name {
            font-size: 16px;
            color: #333333;
          }
          .focus {
            width: 66px;
            height: 25px;
            border: 1px solid #ff5c84;
            font-size: 14px;
            border-radius: 20px;
            text-align: center;
            line-height: 22px;
            color: #ff5c84;
            > span {
              font-size: 20px;
            }
          }
          .watch_more {
            color: #ff678f;
            display: flex;
            justify-content: center;
            align-items: center;
            .right_orange_arrow {
              font-size: 20px;
            }
          }
        }
        .summary {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
</style>
